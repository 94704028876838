@font-face {
  font-family: "WremenaRegular";
  font-style: "regular";
  src: local("Wremena"), url(./fonts/WremenaRegular.woff) format("woff");
}

@font-face {
  font-family: "WremenaBold";
  font-style: bold;
  src: local("Wremena"), url(./fonts/WremenaBold.woff) format("woff");
}

@font-face {
  font-family: "WremenaLight";
  src: local("Wremena"), url(./fonts/WremenaLight.woff) format("woff");
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper-scroll {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;

  scroll-snap-type: y mandatory;
}

.scroll-start {
  scroll-snap-align: start;
  min-height: 100vh;
}

.scroll-center {
  min-height: 100vh;
  scroll-snap-align: center;
}
.scroll-stop {
  scroll-snap-align: end;
}
